// This file is automatically generated by Jets. It is meant to be modified
// for your needs.

// Simple starter css generated by Jets, meant to be overriden
.starter {
  padding: 20px;

  td:first-child {
    min-width: 300px;
  }
  td {
    padding-left: 6px;
  }

  label {
    min-width: 200px;
  }
  input {
    padding: 5px;
  }
}

@import "~bootstrap/scss/bootstrap.scss";